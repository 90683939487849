import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Academia Europea",
      description: "Desarrollo App Web Registro Estudiantil y Plataforma de Clases en Vivo",
      imgUrl: projImg1,
    },
    {
      title: "Innoven",
      description: "Desarrollo Web - Full Stack",
      imgUrl: projImg2,
    },
    {
      title: "Comersal",
      description: "Instructor Power Platform y Dynamics 365",
      imgUrl: projImg3,
    },
    {
      title: "Suplimed El Salvador",
      description: "Asesor Proyecto Power Plarform",
      imgUrl: projImg4,
    },
    {
      title: "Safe Structural Engineering INC",
      description: "Desarrollo de App Web Administrativa Ventas e Inventario",
      imgUrl: projImg5,
    },
    {
      title: "Banco Agricola",
      description: "Asesor API Java ",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Experiencia</h2>
                <p>Amplia experiencia en desarrollo web, análisis de datos y automatización de procesos, con proyectos en aplicaciones web, machine learning y optimización empresarial utilizando Power BI y Power Automate. He mejorado la competitividad de diversas empresas con soluciones tecnológicas innovadoras.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Laboral</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Pro</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Colaboración</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    
                    <Tab.Pane eventKey="third">
                      <p>He participado activamente en proyectos de machine learning e inteligencia artificial para diversas empresas en Venezuela, donde he desarrollado soluciones innovadoras que optimizan procesos y mejoran la toma de decisiones. Mi trabajo ha incluido la creación de modelos predictivos, análisis de datos masivos, y automatización de tareas repetitivas, lo que ha resultado en un aumento significativo de la eficiencia operativa. Además, he colaborado con equipos multidisciplinarios para implementar estas tecnologías en sectores como finanzas, salud y retail, logrando resultados tangibles que han impulsado el crecimiento y la competitividad de las empresas a nivel nacional.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
