import React from 'react';
import whatsappIcon from '../assets/img/whatsapp-icon.png'; // Asegúrate de tener un ícono en la carpeta img

export const FloatingButton = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/50364505253', '_blank'); // Reemplaza con tu número de teléfono
  }

  return (
    <div className="floating-button" onClick={handleWhatsAppClick}>
      <img src={whatsappIcon} alt="WhatsApp" />
    </div>
  );
}
